import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Nav from './components/navBar/Nav';
import { LandingPage } from './pages/LandingPage';
import { LandingPageFin } from './pages/LandingFin';
import './loader.js';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { EventType } from '@azure/msal-browser';
import { useEffect } from 'react';
import { b2cPolicies } from './authconfig';
import { useCookies } from 'react-cookie';
import LinearProgress from '@mui/material/LinearProgress';
import { initializeGA } from './components/_common/useAnalyticsEventTracker';
import Tutorial from './pages/Tutorial';
import { AboutUs } from './pages/AboutUs';
import { Footer } from './components/landingPage/Footer';
const Feedback = lazy(() => import('./pages/Feedback'));
const TextEditor = lazy(() => import('./pages/TextEditor'));
const Account = lazy(() => import('./pages/Account'));
const TOS = lazy(() => import('./pages/Tos'));
const ContactUs = lazy(() => import('./pages/ContactUs'));

const GA_MEASUREMENT_ID = 'G-K6W9F6QMM2'; // Replace with your Measurement ID

type AppProps = {
  pca: IPublicClientApplication;
};

const Pages = () => {
  const [cookies] = useCookies(['checks']);
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  useEffect(() => {
    initializeGA(GA_MEASUREMENT_ID);
  }, []);
  useEffect(() => {
    const callbackId = instance.addEventCallback(
      (event: {
        eventType: EventType;
        payload: {
          account: any;
          idTokenClaims: { oid: string | undefined; sub: string | undefined };
        };
        error: { errorMessage: string | string[] };
      }) => {
        if (
          (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
          event.payload.account
        ) {
        }

        if (event.eventType === EventType.LOGIN_FAILURE) {
          console.log('LOGIN FAILURE');

          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
            const resetPasswordRequest = {
              authority: b2cPolicies.authorities.forgotPassword.authority,
              scopes: [],
            };
            instance.loginRedirect(resetPasswordRequest);
          }
        }
      }
    );

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };

    // eslint-disable-next-line
  }, [instance]);
  return (
    <Routes>
      <Route
        index
        element={
          <Suspense fallback={<LinearProgress />}>
            <Nav />
            <LandingPageFin /> <Footer />
          </Suspense>
        }
      />
      <Route path="lan" element={<LandingPage />} />

      <Route
        path="textEditor"
        element={
          <Suspense fallback={<LinearProgress />}>
            <Nav />
            <TextEditor {...{ documentId: -1, content: '', name: '', checkCookie: cookies.checks }} /> <Footer />
          </Suspense>
        }
      />
      <Route
        path="feedback"
        element={
          <Suspense fallback={<LinearProgress />}>
            <Nav />
            <Feedback />
            <Footer />
          </Suspense>
        }
      />
      <Route
        path="account"
        element={
          <Suspense fallback={<LinearProgress />}>
            <Nav />
            <Account />
            <Footer />
          </Suspense>
        }
      />
      <Route
        path="tos"
        element={
          <Suspense fallback={<LinearProgress />}>
            <Nav />
            <TOS />
            <Footer />
          </Suspense>
        }
      />
      <Route
        path="contact"
        element={
          <Suspense fallback={<LinearProgress />}>
            <Nav />
            <ContactUs />
            <Footer />
          </Suspense>
        }
      />
      <Route
        path="about"
        element={
          <Suspense fallback={<LinearProgress />}>
            <Nav />
            <AboutUs />
            <Footer />
          </Suspense>
        }
      />
      <Route
        path="tutorial"
        element={
          <Suspense fallback={<LinearProgress />}>
            <Nav />
            <Tutorial />
            <Footer />
          </Suspense>
        }
      />
    </Routes>
  );
};

function App({ pca }: AppProps) {
  return (
    <>
      <link rel="icon" href="./pages/assets/icon.png" />
      <React.StrictMode>
        <MsalProvider instance={pca}>
          <BrowserRouter>
            <Pages />
          </BrowserRouter>
        </MsalProvider>
      </React.StrictMode>
    </>
  );
}

export default App;
