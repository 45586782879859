import { Container, Grid, Stack, Typography } from '@mui/material';
import grace from '../../assets/graceHeadshot.jpg';
import bridget from '../../assets/bridgetHeadshot.jpg';
import tristan from '../../assets/tristanHeadshot.jpg';
import kevin from '../../assets/kevinHeadshot.jpg';
import bill from '../../assets/billHeadshot.jpg';
import santiago from '../../assets/santiagoHeadshot.jpg';
import victoria from '../../assets/victoriaHeadshot.jpg';
import nick from '../../assets/nickHeadshot.jpg';
import josh from '../../assets/joshHeadshot.jpg';

const FoundersStory = () => (
  <>
    <Container maxWidth="xl" sx={{ my: 5, textAlign: 'center' }}>
      <Typography variant="h1" my={2} sx={{ fontSize: '3rem' }}>
        Our Leadership Team
      </Typography>
      <Grid container spacing={4} direction="row" mb={4} textAlign={'center'}>
        <Grid item xs={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Grace Clausen"
              style={{ width: '150px', border: '1px solid #dcdfe2', borderRadius: '50%', padding: 0 }}
              src={grace}
            />
            <Typography variant="h2" fontSize={'1.5rem'} fontWeight={'bold'} pt={2} pb={1}>
              Grace Clausen
            </Typography>
            <Typography variant="body1" maxWidth={'350px'}>
              Co-founder & CEO
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Bridget Peterkin"
              style={{ width: '150px', border: '1px solid #dcdfe2', borderRadius: '50%', padding: 0 }}
              src={bridget}
            />
            <Typography variant="h2" fontSize={'1.5rem'} fontWeight={'bold'} pt={2} pb={1}>
              Bridget Peterkin
            </Typography>
            <Typography variant="body1" maxWidth={'350px'}>
              Co-founder & CPO
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Tristan Curd"
              style={{ width: '150px', border: '1px solid #dcdfe2', borderRadius: '50%', padding: 0 }}
              src={tristan}
            />
            <Typography variant="h2" fontSize={'1.5rem'} fontWeight={'bold'} pt={2} pb={1}>
              Tristan Curd
            </Typography>
            <Typography variant="body1" maxWidth={'350px'}>
              Project Manager
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Santiago Giraldo"
              style={{ width: '150px', border: '1px solid #dcdfe2', borderRadius: '50%', padding: 0 }}
              src={santiago}
            />
            <Typography variant="h2" fontSize={'1.5rem'} fontWeight={'bold'} pt={2} pb={1}>
              Santiago Giraldo
            </Typography>
            <Typography variant="body1" maxWidth={'350px'}>
              Developer
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Victoria Chin"
              style={{ width: '150px', border: '1px solid #dcdfe2', borderRadius: '50%', padding: 0 }}
              src={victoria}
            />
            <Typography variant="h2" fontSize={'1.5rem'} fontWeight={'bold'} pt={2} pb={1}>
              Victoria Chin
            </Typography>
            <Typography variant="body1" maxWidth={'350px'}>
              Developer
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Nick Lauver"
              style={{ width: '150px', border: '1px solid #dcdfe2', borderRadius: '50%', padding: 0 }}
              src={nick}
            />
            <Typography variant="h2" fontSize={'1.5rem'} fontWeight={'bold'} pt={2} pb={1}>
              Nick Lauver
            </Typography>
            <Typography variant="body1" maxWidth={'350px'}>
              Marketing & Business Development
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Josh Feng"
              style={{ width: '150px', border: '1px solid #dcdfe2', borderRadius: '50%', padding: 0 }}
              src={josh}
            />
            <Typography variant="h2" fontSize={'1.5rem'} fontWeight={'bold'} pt={2} pb={1}>
              Josh Feng
            </Typography>
            <Typography variant="body1" maxWidth={'350px'}>
              Developer
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Kevin Tran"
              style={{ width: '150px', border: '1px solid #dcdfe2', borderRadius: '50%', padding: 0 }}
              src={kevin}
            />
            <Typography variant="h2" fontSize={'1.5rem'} fontWeight={'bold'} pt={2} pb={1}>
              Kevin Tran
            </Typography>
            <Typography variant="body1" maxWidth={'350px'}>
              Intern
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Bill Anderson"
              style={{ width: '150px', border: '1px solid #dcdfe2', borderRadius: '50%', padding: 0 }}
              src={bill}
            />
            <Typography variant="h2" fontSize={'1.5rem'} fontWeight={'bold'} pt={2} pb={1}>
              Bill Anderson
            </Typography>
            <Typography variant="body1" maxWidth={'350px'}>
              Coach and advisor
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </>
);

export default FoundersStory;
